<template>
  <div class="dayWray">
    <div class="lineSha"></div>
    <div class="lineri"></div>
    <div
      class="day day1"
      :style="{ transform: transform1, zIndex: zIndex1 }"
      v-touch:swipeleft="leftSlide"
      v-touch:swiperight="rightSlide"
    >
      <div>
        <img
          class="firstdy"
          v-if="oldInfo.day == oldInfo.startDay"
          src="../assets/images/first.png"
          alt=""
        />
        <img
          class="firstdy"
          v-if="oldInfo.day == today"
          src="../assets/images/tody.png"
          alt=""
        />
        <img
          class="firstdy"
          v-if="oldInfo.day == oldInfo.endDay"
          src="../assets/images/end.png"
          alt=""
        />
      </div>

      <div
        class="imgWrap"
        :style="{
          backgroundImage: `url(${
            oldInfo.week
              ? I[oldInfo.week]
              : 'https://img.ok-bug.com/time/wei.png'
          })`,
        }"
      >
        <div class="arr" @click="onPage">
          <div class="p1">
            <span class="s1">TIME</span><span class="s2">安 排</span>
          </div>
          <div class="p2" v-if="oldInfo.startDay">
            {{ oldInfo.startDay.replace(/-/g, ".") }}-{{
              oldInfo.endDay.replace(/-/g, ".")
            }}
          </div>
          <div class="p3">——{{info.allSumDay}}天</div>
        </div>

        <div class="dayWrap" v-if="oldInfo.day">
          <div class="p1">周{{ oldInfo.day | getWeekDay }}</div>
          <div class="p2">{{ oldInfo.day }}</div>
        </div>

        <div class="buttons" v-if="oldInfo.allDay>0">
          <mu-button
            :disabled="btnDisabled || sumDay == info.startDay"
            class="button"
            small
            fab
            color="rgba(0,0,0,.2)"
            @click="onCut"
          >
            <mu-icon value="keyboard_arrow_left"></mu-icon>
          </mu-button>
          <mu-button
            :disabled="btnDisabled || sumDay == today"
            class="button"
            small
            fab
            color="rgba(0,0,0,.2)"
            @click="onAdd"
          >
            <mu-icon value="keyboard_arrow_right"></mu-icon>
          </mu-button>
        </div>
      </div>
      <div class="infoWrap" v-if="oldInfo.week">
        <div class="line"></div>
        <div v-for="(item, key) in oldInfo.events" :key="key" class="itemWrap">
          <div
            class="dot"
            :style="{
              background:
                today === oldInfo.day
                  ? '#ccc'
                  : item.ischeck === 1
                  ? '#10A913'
                  : '#ccc',
            }"
          ></div>
          <div>
            <span class="p1">{{ item.time }}</span>
            <div class="icon"><img :src="item.icon" alt="" /></div>
            <span class="p2">{{ item.name }}</span>
          </div>
          <div v-if="today === oldInfo.day">
            <div class="checkBox" v-if="item.ischeck === 1">
              <img src="../assets/images/check.png" alt="" />
            </div>
            <div class="checkBox" v-else @click="onCheck(item.id, key)"></div>
          </div>
          <div v-else>
            <span class="p3" v-if="today !== oldInfo.day">{{
              item.checkDate | formatDateFilter
            }}</span>
          </div>
        </div>

        <div
          class="complete"
          v-if="oldInfo.isAll === 1 && today !== oldInfo.day"
        >
          <img src="https://img.ok-bug.com/time/all.png" alt="" />
        </div>
        <!-- <div class="complete" v-if="oldInfo.isAll === 0 && today!==oldInfo.day"><img src="https://img.ok-bug.com/time/no.png" alt=""></div> -->
      </div>

      <div class="warning" v-else>
         <div v-if="info.allDay>0">- 暂 无 安 排 -</div>
          <div v-else>
            计划于 <span class="undeline">{{info.startDay}}</span> 正式开始
          </div>

      </div>
    </div>

    <div
      class="day day2"
      v-if="info"
      :style="{ transform: transform2, zIndex: zIndex2 }"
      v-touch:swipeleft="leftSlide"
      v-touch:swiperight="rightSlide"
    >
      <div>
        <img
          class="firstdy"
          v-if="info.day == info.startDay"
          src="../assets/images/first.png"
          alt=""
        />
        <img
          class="firstdy"
          v-if="info.day == today"
          src="../assets/images/tody.png"
          alt=""
        />
        <img
          class="firstdy"
          v-if="info.day == info.endDay"
          src="../assets/images/end.png"
          alt=""
        />
      </div>

      <div
        class="imgWrap"
        :style="{
          backgroundImage: `url(${
            info.week ? I[info.week] : 'https://img.ok-bug.com/time/wei.png'
          })`,
        }"
      >
        <div class="arr" @click="onPage">
          <div class="p1">
            <span class="s1">TIME</span><span class="s2">安 排</span>
          </div>
          <div class="p2">
            {{ info.startDay.replace(/-/g, ".") }}-{{
              info.endDay.replace(/-/g, ".")
            }}
          </div>
          <div class="p3">——{{info.allSumDay}}天</div>
        </div>

        <div class="dayWrap" v-if="info.day">
          <div class="p1">周{{ info.day | getWeekDay }}</div>
          <div class="p2">{{ info.day }}</div>
        </div>

        <div class="buttons" v-if="info.allDay>0">
          <mu-button
            :disabled="btnDisabled || sumDay == info.startDay"
            class="button"
            small
            fab
            color="rgba(0,0,0,.2)"
            @click="onCut"
          >
            <mu-icon value="keyboard_arrow_left"></mu-icon>
          </mu-button>
          <mu-button
            :disabled="btnDisabled || sumDay == today"
            class="button"
            small
            fab
            color="rgba(0,0,0,.2)"
            @click="onAdd"
          >
            <mu-icon value="keyboard_arrow_right"></mu-icon>
          </mu-button>
        </div>
        <!--v-if="sumDay !== info.startDay"   v-if="sumDay !== today" -->
      </div>
      <div class="infoWrap" v-if="info.week">
        <div class="line"></div>
        <div v-for="(item, key) in info.events" :key="key" class="itemWrap">
          <div
            class="dot"
            :style="{
              background:
                today === info.day
                  ? '#ccc'
                  : item.ischeck === 1
                  ? '#10A913'
                  : '#ccc',
            }"
          ></div>
          <div>
            <span class="p1">{{ item.time }}</span>
            <div class="icon"><img :src="item.icon" alt="" /></div>

            <span class="p2">{{ item.name }}</span>
          </div>
          <div v-if="today === info.day">
            <div class="checkBox" v-if="item.ischeck === 1">
              <img src="../assets/images/check.png" alt="" />
            </div>
            <div class="checkBox" v-else @click="onCheck(item.id, key)"></div>
          </div>
          <div v-else>
            <span class="p3" v-if="today !== info.day">{{
              item.checkDate | formatDateFilter
            }}</span>
          </div>
        </div>

        <div class="complete" v-if="info.isAll === 1 && today !== info.day">
          <img src="https://img.ok-bug.com/time/all.png" alt="" />
        </div>
      </div>

      <div class="warning" v-else>
          <div v-if="info.allDay>0">- 暂 无 安 排 -</div>
          <div v-else>
            计划于 <span class="undeline">{{info.startDay}}</span> 正式开始
          </div>

      </div>
    </div>
    <div class="sum" v-if="info.allDay>0">
      @亲，已坚持{{ info.allDay }}天，累计完成{{ info.count }}天
    </div>
  </div>
</template>
<script>
import {
  getdiffdate,
  getweekday,
  formatDate,
  addDate,
  GetNumberOfDays,
} from "../utils";

const I = {
  一: "https://img.ok-bug.com/time/1.png",
  二: "https://img.ok-bug.com/time/2.png",
  三: "https://img.ok-bug.com/time/3.png",
  四: "https://img.ok-bug.com/time/4.png",
  五: "https://img.ok-bug.com/time/5.png",
  六: "https://img.ok-bug.com/time/6.png",
  日: "https://img.ok-bug.com/time/7.png",
};
// , getweekday
export default {
  directives: {
    //滑动指令
    touch: {
      bind: function (el, binding) {
        var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0;
        var direction = "";
        //滑动处理
        var startX, startY;

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI;
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY;
          var dx = endX - startX;
          var result = 0;

          //如果滑动距离太短
          if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
            return result;
          }

          var angle = GetSlideAngle(dx, dy);
          if (angle >= -45 && angle < 45) {
            result = "swiperight";
          } else if (angle >= 45 && angle < 135) {
            result = "swipeup";
          } else if (angle >= -135 && angle < -45) {
            result = "swipedown";
          } else if (
            (angle >= 135 && angle <= 180) ||
            (angle >= -180 && angle < -135)
          ) {
            result = "swipeleft";
          }
          return result;
        }

        el.addEventListener(
          "touchstart",
          function (ev) {
            startX = ev.touches[0].pageX;
            startY = ev.touches[0].pageY;

            //判断长按
            timeOutEvent = setTimeout(() => {
              timeOutEvent = 0;
              if (touchType === "press") {
                binding.value();
              }
            }, 500);
          },
          false
        );

        el.addEventListener("touchmove", function () {
          clearTimeout(timeOutEvent);
          timeOutEvent = 0;
        });

        el.addEventListener(
          "touchend",
          function (ev) {
            var endX, endY;
            endX = ev.changedTouches[0].pageX;
            endY = ev.changedTouches[0].pageY;
            direction = GetSlideDirection(startX, startY, endX, endY);

            clearTimeout(timeOutEvent);

            switch (direction) {
              case 0:
                break;
              case "swipeup":
                if (touchType === "swipeup") {
                  binding.value();
                }
                break;
              case "swipedown":
                if (touchType === "swipedown") {
                  binding.value();
                }
                break;
              case "swipeleft":
                if (touchType === "swipeleft") {
                  binding.value();
                }
                break;
              case "swiperight":
                if (touchType === "swiperight") {
                  binding.value();
                }
                break;
            }
          },
          false
        );
      },
    },
  },
  filters: {
    formatDateFilter(v) {
      return v ? formatDate(new Date(v), "hh:mm:ss") : "-";
    },
    getDaySum(v) {
      return GetNumberOfDays(v, formatDate(new Date(), "yyyy-MM-dd"));
    },
    getWeekDay(v) {
      return getweekday(v);
    },
  },
  data() {
    return {
      info: "",
      oldInfo: {},
      today: formatDate(new Date(), "yyyy-MM-dd"),
      dayN: 0,
      sumDay: formatDate(new Date(), "yyyy-MM-dd"),
      transform2: "translateX(-100%)",
      zIndex2: -1,
      transform1: "translateX(0%)",
      zIndex1: 1,
      isRun: true,
      I,
      btnDisabled: false,
      isChange: true,
      startX: 0, //开始触摸的位置
      moveX: 0, //滑动时的位置
      endX: 0, //结束触摸的位置
      disX: 0, //移动距离
    };
  },
  methods: {
    leftSlide() {
      if (this.sumDay == this.info.startDay) {
        return;
      }
      this.onCut();
    },
    rightSlide() {
      if (this.sumDay == this.today) {
        this.$router.push("/");
        return;
      }
      this.onAdd();
    },

    onPage() {
      this.$router.push("/");
    },
    changePage() {
      if (this.isRun) {
        console.log(3);
        this.isRun = !this.isRun;
        this.transform2 = "translateX(0%)";
        this.zIndex2 = this.zIndex2 + 3;
        setTimeout(() => {
          this.btnDisabled = false;
          this.transform1 = "translateX(-100%)";
        }, 400);
      } else {
        console.log(4);
        this.isRun = !this.isRun;
        this.transform1 = "translateX(0%)";
        this.zIndex1 = this.zIndex1 + 3;
        setTimeout(() => {
          this.btnDisabled = false;
          this.transform2 = "translateX(-100%)";
        }, 400);
      }
    },

    changePage1() {
      console.log(9);
      if (this.isRun) {
        this.isRun = !this.isRun;
        this.transform2 = "translateX(0%)";
        setTimeout(() => {
          this.btnDisabled = false;
          this.transform1 = "translateX(-100%)";
          setTimeout(() => {
            this.zIndex2 = this.zIndex2 + 3;
          }, 100);
        }, 290);
      } else {
        this.isRun = !this.isRun;
        this.transform1 = "translateX(0%)";
        setTimeout(() => {
          this.btnDisabled = false;
          this.transform2 = "translateX(-100%)";
          setTimeout(() => {
            this.zIndex1 = this.zIndex1 + 3;
          }, 100);
        }, 290);
      }
    },

    async onCut() {
      if(this.info.allDay<0){
        return
      }
      this.btnDisabled = true;
      this.dayN--;
      const day = addDate(new Date(), this.dayN);
      this.sumDay = day;
      const list = await this.$api.getAllDayEvent({
        data: { day, user: this.$store.user },
      });
      // this.oldInfo = list

      if (this.isChange) {
        this.isChange = !this.isChange;
        this.oldInfo = list;
      } else {
        this.isChange = !this.isChange;
        this.info = list;
      }

      // if(this.isRun) {

      //     console.log(8)
      //     this.isRun = !this.isRun
      //     this.transform2 = 'translateX(0%)'
      //     setTimeout(()=>{
      //         this.transform1 = 'translateX(-100%)'
      //         this.zIndex1 = this.zIndex1+3
      //         this.btnDisabled = false
      //     }, 400)
      // }else {
      //     console.log(9)
      //    this.transform1 = 'translateX(0%)'
      //     this.zIndex1 = this.zIndex1+3

      //    this.isRun = !this.isRun
      //    setTimeout(()=>{
      //         this.transform2 = 'translateX(-100%)'

      //         this.btnDisabled = false
      //    }, 400)
      // }

      this.changePage1();
      //  this.getList(day)
    },
    async onAdd() {

       if(this.info.allDay<0){
        return
      }
      // this.transform2 = 'translateX(-100%)'
      // this.transform1 = 'translateX(0%)'

      this.btnDisabled = true;
      this.dayN++;
      const day = addDate(new Date(), this.dayN);
      this.sumDay = day;
      const list = await this.$api.getAllDayEvent({
        data: { day, user: this.$store.user },
      });
      if (this.isChange) {
        this.isChange = !this.isChange;
        this.oldInfo = list;
      } else {
        this.isChange = !this.isChange;
        this.info = list;
      }
      this.changePage();

      //  this.transform2 = 'translateX(0%)'
      // setTimeout(()=>{
      //     this.transform1 = 'translateX(-100%)'
      //    this.zIndex1 = this.zIndex1+3
      //    this.btnDisabled = false
      // }, 400)
      //  this.getList(day)
    },
    login() {
      const userName = this.$store.user;
      if (!userName) {
        this.$router.push("/user");
      }
    },
    // 设置
    setDefault() {
      var stime = '2020-10-30'; //开始日期
      var etime = '2021-04-30'; //结束日期
      const dates = getdiffdate(stime, etime);
      let data = [];
      dates.forEach((item) => {
        const week = getweekday(item);
        // const type = week === "日" ? 2 : 1;
      // const type = 3
        const type =  week === "日" || week === '六' ? 9: 8
        data.push({ day: item, type, week, user: "big big sun" });
      });
      this.$api.addAllDayEvent({ data: { data } });
    },

    async getList(day) {
      const list = await this.$api.getAllDayEvent({
        data: { day, user: this.$store.user },
      });

      list['allDay'] = GetNumberOfDays(list.startDay, formatDate(new Date(), "yyyy-MM-dd"))

      list['allSumDay'] =  GetNumberOfDays(list.startDay, list.endDay)
      //   const list1 = await this.$api.getAllDayEvent({data:{day, user:this.$store.user}})
      this.changePage(list);

      //   setTimeout(()=>{
      this.info = list;

      console.log(list,'list')

      //   },500)
    },

    async onCheck(id, index) {
      if (this.$store.user) {
        const data = { day_id: id, event_id: this.info.id };
        await this.$api.setEventCheck({ data });
        //  this.getList()

        const events = this.info.events;
        events[index].ischeck = 1;

        const all = events.filter((v) => v.ischeck === 1);

        if (all.length === events.length) {
          this.$api.setAll({ data: { id: this.info.id } });
        }
      }
    },
  },
  mounted() {
      // this.setDefault()
    //   this.login()
    this.getList(this.today);
    // this.getList('2021-01-11')
  },
};
</script>
<style lang="less">
.lineSha {
  width: 10px;
  height: 100vh;
  position: fixed;
  background: #fff;
  top: 0;
  left: -10px;
  z-index: 9999999999;
  box-shadow: 2px 0 15px 0 rgba(0, 0, 0, 0.7);
}

.lineri {
  width: 10px;
  height: 100vh;
  position: fixed;
  background: #fff;
  top: 0;
  right: 30px;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.6);
}

.firstdy {
  position: absolute;
  z-index: 99999;
  width: 30px;
  right: 10px;
  top: 10px;
  animation: 0.4s aa forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

@keyframes aa {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dayWray {
  padding-right: 30px;

  position: relative;

  width: 100%;
  background: url(https://img.ok-bug.com/time/desc.png) no-repeat;
  background-position: 100% 0;
  background-attachment: fixed;
  overflow: auto;
  height: 100vh;
  background-size: 30px 100%;
  // &:after {
  //     content: '';
  //     display: block;
  //     width: 30px;
  //     height: 100%;

  //     position: absolute;
  //     top:0;
  //     right: 0;
  //     z-index: -1;
  //     // border-left: 6px solid #FFFFFF;
  // }
}
.day {
  background: #f7f7f7;
  // box-shadow: 2px 0 4px 0px #8B8B8E;
  min-height: 100vh;
  transition: all 0.3s;

  position: absolute;
  width: calc(100% - 30px);

  top: 0;
}
.imgWrap {
  position: relative;
  height: 330px;
  // background-image: url(https://img.ok-bug.com/time/bg.png);
  background-size: contain;
  background-attachment: fixed;
  img {
    width: 100%;
  }

  .dayWrap {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    bottom: -15px;
    width: 110px;
    padding: 5px 0;
    padding-right: 10px;

    text-align: right;
    font-weight: bold;
    right: 15px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    .p1 {
      color: #424242;
      font-size: 16px;
    }
    .p2 {
      color: #828282;
      font-size: 12px;
    }
  }

  .buttons {
    position: absolute;
    left: 15px;
    bottom: 10px;

    .button {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
}

.infoWrap {
  padding: 20px 20px;
  position: relative;
  min-height: calc(100vh - 330px);
  padding-bottom: 40px;
  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    background: #dedede;
    top: 0;
    left: 80px;
  }
  // background: red;

  .p1 {
    color: #979797;
    font-size: 12px;
    font-weight: bold;

    width: 50px;
    display: inline-block;
  }

  .p2 {
    color: #6f6f6f;
    font-size: 14px;
    font-weight: bold;
  }
  .p3 {
    color: #828282;
    font-size: 12px;
    padding-left: 10px;
  }

  .icon {
    width: 20px;
    vertical-align: middle;
    margin-right: 20px;
    margin-left: 30px;
    display: inline-block;
    img {
      width: 100%;
    }
  }

  .itemWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: relative;

    .dot {
      width: 15px;
      height: 15px;
      background: #ccc;
      position: absolute;
      border-radius: 50%;
      left: 53px;
      border: 3px solid #f7f7f7;
      box-sizing: border-box;
    }
  }

  .checkBox {
    width: 20px;
    height: 20px;
    background: #dedede;
    border-radius: 2px;
    // border-radius: 50%;
    img {
      width: 100%;
    }
  }
}

.warning {
  text-align: center;
  color: #646464;
  margin-top: 100px;
}

.sum {
  position: fixed;
  text-align: right;
  color: #a2a2a2;
  bottom: 0px;
  right: 30px;
  font-size: 12px;
  background: #f7f7f7;
  width: 100%;
  padding: 10px 6px;
  box-sizing: border-box;
  padding-right: 15px;
  z-index: 99;
}

.arr {
  color: #424242;
  padding: 10px;
  padding-left: 20px;
  padding-top: 20px;

  .p1 {
  font-weight: bolder;

    .s1 {
      font-size: 36px;
    }

    .s2 {
      font-size: 16px;
      padding-left: 10px;
    }
  }
  .p2 {
    font-size: 12px;
    color: #646464;
  font-weight: bolder;
    
  }

  .p3 {
    font-size: 12px;
    color: #646464;
    margin-top: 10px;
  font-weight: bold;

  }
}

.complete {
  img {
    width: 200px;
    height: 200px;
  }
  position: absolute;
  bottom: 40px;
  right: 30px;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: all 0.2s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for below version 2.1.8 */ {
  transform: translate(-100%);
  opacity: 0;
}

.undeline {
  border-bottom: dashed 1px #E05F58;
    line-height: 0.9;
}
</style>
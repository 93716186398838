<template>
  <div class="about">
        <img src="../assets/images/time.png" class="time1" alt="">
        <img src="../assets/images/time.png" class="time2" alt="">


      <div class="userBox">

        <mu-tabs :value.sync="active" color="transparent" @change="changeTab">
          <mu-tab>登录</mu-tab>
          <mu-tab>注册</mu-tab>
        </mu-tabs>


         <div v-if="active === 0" class="container">
           <mu-text-field v-model="name" full-width placeholder="用户名" ></mu-text-field>
           <mu-button color="primary" @click="loginSubmit">立即登录</mu-button>
         </div>

          <div v-if="active === 1" class="container">
           <mu-text-field v-model="name" full-width placeholder="用户名" action-icon="refresh" max-length="10" :action-click="changeName"></mu-text-field>
           <mu-button color="primary" @click="registSubmit">立即注册</mu-button>
         </div>

         <div>
           <!-- 请牢记用户名。 -->
         </div>
      </div>

      <div class="footer">- 时光陪伴 -</div>
  </div>
</template>
<script>
import md5 from 'js-md5';
export default {
  
  data(){
    return {
      name:'',
      userName:'',
      active: 0
    }
  },
  methods:{
    changeTab(tab){
      if(tab === 0) {
        this.name = ''
      }else {
        this.createName()
      }
    },
    login(){
      this.userName = this.$store.user
      if(this.userName) {
        this.$router.push('/')
      }else {
        if(this.active === 1) {
            this.createName()
        }
      }
    },

    async loginSubmit(){
       await this.$api.login({data: {name: this.name, login: 1}})
       this.$store.user = this.name
       this.$router.push('/')
    },

    async registSubmit(){
       await this.$api.login({data: {name: this.name}})
       this.$store.user = this.name
       this.$router.push('/')
    },

    createName(){
     const date2 = (new Date()).valueOf().toString()
      const code = md5(date2).substring(0,5)
      this.name = code
    },
    changeName(){
      this.createName()
    }
  },
  mounted(){
    this.login()
    // console.log(3)


  }
}
</script>
<style lang="less">

  .about {
    padding: 10px;
  }

  .time1 {
  position: fixed;
  z-index: -1;
  opacity: 0.3;
  width: 80%;
}
.time2 {
  z-index: -2;
  width:20%;
}

.userBox {
  padding-top: 20px;

  h4 {
    color: #424242;
    font-size: 20px;
    padding-bottom: 15px;
  }
}

.footer {
  text-align: center;
  position: fixed;
  bottom: 20px;
  width: 100%;
  font-size: 12px;
  left:0;
}

.mu-tab {
  color: #424242;
  font-weight: bold;
  padding-left: 0;
}

.mu-tab-link-highlight {
  background-color: #2196f3;
}

.container {
  margin-top: 30px;
}

</style>